.App {
  text-align: center;
}

.content {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.content-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
  margin-bottom: 1.5rem;
}

.top-bar {
  background: #333;
  padding: 1rem;
  margin-bottom: 2rem;
}

.top-bar a {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
}

.top-bar a:hover {
  color: #ddd;
}
