@font-face {
  font-family: "DMSans";
  src: url("../assets/DMSans-VariableFont_opsz,wght.ttf");
}

.top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 67px;
  background-color: #73937e;
  /* background-color: */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 0.8s ease-in-out;
}

.top-bar.show-bar {
  transition: transform 0.8s ease-in-out !important;
  transform: translateY(0);
}

.top-bar.hide-bar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out !important;
}

.on-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 5px; 
  margin-left: -10px;  /*Pull logo left to counter the top-bar padding*/
}

.on-logo img {
  height: 85%;  /* Slightly reduce height to move up */
  width: auto;
  object-fit: contain;
  margin-top: -2px;  /* Fine-tune vertical position */
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
  cursor: pointer;
}

.menu-icon div {
  width: 25px;
  height: 3px;
  background-color: #28262c;
  margin: 4px 0;
  transition: 0.3s;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -250px; /* Hidden by default */
  background-color: rgba(115, 147, 126, 0.9); /* Background color with opacity */
  color: #28262c;
  z-index: 999;
  transition: right 0.3s ease-in-out, top 0.2s ease-in-out, opacity 0.3s ease-in-out; /* Added transition for top position */
  padding-top: 65px; /* Adjust padding to match the height of the top bar */
  font-size: 24px;
  text-align: right;
}

.sidebar.open {
  right: 0;
}

.sidebar ul {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.sidebar li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(40, 38, 44, 0.2);
}

.sidebar li:last-child {
  border-bottom: none;
}

.sidebar ul li a {
  color: #28262c;
  text-decoration: none;
  display: block;
  padding: 12px 0;
  font-size: 24px;
  font-weight: normal;
  width: calc(100% - 20px); /* Ensures the link covers the full width of the sidebar */
  height: 100%; /* Ensures the link covers the full height of the list item */
  transition: opacity 0.3s ease;
}

.sidebar ul li a:hover {
  opacity: 0.8;
}

.dropdown-content {
  display: none;
  padding-left: 20px;
  margin: 0;
  background-color: rgba(40, 38, 44, 0.05);
  border-top: 1px solid rgba(40, 38, 44, 0.2);
}

.dropdown-content li {
  margin: 0;
  padding: 0;
  border-bottom: none;
}

.dropdown-content li:last-child {
  border-bottom: 1px solid rgba(40, 38, 44, 0.2);
}

.dropdown-content .sidebar-link {
  font-size: 24px;
  padding: 12px 0;
  padding-left: 12px;
  transition: opacity 0.3s ease;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  background: none;
  border: none;
  color: inherit;
  font-size: 24px;
  font-weight: normal;
  text-align: right;
  font-family: inherit;
  transition: opacity 0.3s ease;
}

.dropdown-toggle:hover {
  opacity: 0.8;
}

.dropdown-icon {
  margin-left: 8px;
  transition: transform 0.3s ease;
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}

.dropdown.open .dropdown-icon {
  transform: rotate(45deg);
}

.dropdown.open .dropdown-content {
  display: block;
}

.sidebar-toggle-btn {
  background: none;
  border: none;
  color: #28262c;
  font-size: 32px;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-toggle-btn:hover {
  opacity: 0.8;
}