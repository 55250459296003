@font-face {
  font-family: "GeistMono";
  src: url("../assets/GeistMono-Regular.ttf");
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scroll-snap-type: y proximity;
  font-size: 100%
}

.home-page-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
}

.intro-section {
  width: 100%;
  max-width: 900px;
  text-align: left;
  margin-top: 50px;
  position: relative;
  overflow-x: hidden;
}

.definition {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.greeting {
  font-size: 2.5rem;
  color: #28262c;
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-align: left;
}

.name {
  font-size: clamp(4rem, 12vw, 8rem);
  font-weight: bold;
  margin: 0;
  color: #28262c;
  line-height: 1;
  position: relative;
  text-align: left;
  width: fit-content;
  max-width: 100%;
  display: block;
}

.description {
  font-size: 2.5rem;
  color: #28262c;
  margin-top: 1rem;
  font-weight: 500;
  text-align: left;
}

/* Reset all links to have no special styling by default */
a {
  color: inherit;
  text-decoration: none;
}

/* Apply special styling only to links within main content */
.intro-section a,
.definition a {
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 0.1em;
  padding: 0.1em 0;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.intro-section a:hover,
.definition a:hover {
  opacity: 1;
  background-size: 100% 0.2em;
  background-color: rgba(40, 38, 44, 0.05);
}

/* Keep inherited colors for all links */
a:link,
a:visited {
  color: inherit;
}

.projects-section {
  max-width: 900px;
  margin: 8rem auto;
  text-align: left;
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  margin-top: 4rem;
}

.project-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-bottom: 8rem;
  align-items: center;
}

.project-card.reverse {
  grid-template-columns: 1fr 1fr;
  direction: rtl;
}

.project-card.reverse .project-content {
  direction: ltr;
}

.project-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
  width: 100%;
}

.project-image {
  direction: ltr;
  width: 100%;
  max-width: 500px;
  height: 350px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.project-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.project-content h2 a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #28262c;
  transition: color 0.2s ease;
}

.project-content h2 a:hover {
  color: #73937e;
}

.project-content h2 .github-icon {
  width: 1.2rem;
  height: 1.2rem;
  opacity: 0.6;
}

.project-description {
  margin-bottom: 1.5rem;
  color: #666;
  line-height: 1.6;
}

.project-tech {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.project-tech span {
  font-family: "GeistMono", monospace;
  font-size: 0.9rem;
  color: rgba(40, 38, 44, 0.7);
  background: rgba(40, 38, 44, 0.05);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
}

.github-button {
  margin-top: auto;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  color: #73937e;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 2px solid #73937e;
}

.github-button:hover {
  color: #28262c;
  background-color: #73937e;
  transform: translateY(-2px);
}

.github-button .github-icon {
  width: 1.2rem;
  height: 1.2rem;
}

.project-links {
  display: flex;
  gap: 1.5rem;
}

.project-links a {
  color: #28262c;
  text-decoration: none;
  font-size: 1rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.project-links a:hover {
  opacity: 1;
}

.project-card:hover .project-image img {
  transform: scale(1.03);
}

/* Responsive design */
@media (max-width: 720px) {
  .home-page-content {
    padding-top: 80px;
  }

  .intro-section {
    margin-top: 30px;
  }

  .name {
    font-size: clamp(2.5rem, 8vw, 4rem);
    white-space: normal;
  }
}

@media (max-width: 768px) {
  .project-card {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .project-content {
    padding: 0;
  }

  .projects-list {
    gap: 6rem;
  }
}

.skills-section {
  max-width: 900px;
  margin: 8rem auto;
  text-align: left;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.skill-category {
  background: rgba(40, 38, 44, 0.02);
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.skill-category:hover {
  transform: translateY(-5px);
  background: rgba(40, 38, 44, 0.04);
}

.skill-category h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #28262c;
}

.skill-category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skill-category li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: rgba(40, 38, 44, 0.8);
}

.skill-category li:last-child {
  margin-bottom: 0;
}

.layout-debug {
  outline: 2px solid red;
}
