.home-page-content {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.intro-section {
  font-size: 24px;
  text-align: center;
  margin: 40px 0;
}

.greeting {
  margin-bottom: 20px;
  color: #333;
}

.animate-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate-in.show {
  opacity: 1;
  transform: translateY(0);
}
